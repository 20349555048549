import React from "react"

export const Ul: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="mx-auto mt-4 font-serif prose prose-xl text-gray-500 prose-green">
    <ul id={id}>{children}</ul>
  </div>
)

export const Li: React.FC<{ id?: string }> = ({ children, id }) => (
  <li id={id}>{children}</li>
)

import React from "react"

export const Youtube: React.FC<{ url: string }> = ({ url }) => {
  const id = url.split("v=")[1].split("&")[0]
  const isEmbedUrl = url.includes("/embed/")
  const embedUrl = isEmbedUrl ? url : `https://www.youtube.com/embed/${id}`

  return (
    <div className="relative max-w-screen-md mx-auto mt-8">
      <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
        <iframe
          className="absolute inset-0 w-full h-full"
          src={embedUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

import React from "react"

export const Quote: React.FC<{ credit?: string }> = ({ children, credit }) => (
  <blockquote className="px-10 mx-auto mt-8 font-serif text-xl leading-10 prose prose-xl text-gray-900">
    <span className="text-gray-500">« </span>
    <span className="font-bold">{children}</span>
    <span className="text-gray-500"> »</span>
    {credit && <span className="font-normal text-gray-500"> - {credit}</span>}
  </blockquote>
)

import React from "react"
import { Link, graphql } from "gatsby"

import { Navigation } from "../components/Navigation"
import { Breadcrumb } from "../components/Breadcrumb"
import { Mdx } from "../components/Mdx"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Products } from "../components/Products"
import { MDXRenderer } from "gatsby-plugin-mdx"

const Article = ({ data, pageContext }) => {
  const { previous, next } = data
  const article = data.article.frontmatter
  const body = data.article.body

  const products = pageContext.products || []
  const category = data.category

  const breadcrumbs = [
    { label: "Articles", url: "/articles/" },
    { label: category.name, url: `/articles/${article.category}/` },
    {
      label: article.title,
      url: `/articles/${article.category}/${article.url}/`,
    },
  ]

  return (
    <Layout>
      <SEO
        title={article.title}
        description={article.description}
        breadcrumbs={breadcrumbs}
        image={article.image.childImageSharp.fluid.src}
      />
      <Navigation />
      <Breadcrumb breadcrumbs={breadcrumbs} />

      <Mdx>
        <div className="relative pt-8 overflow-hidden bg-gray-50">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-lg max-w-prose">
              <div
                className="w-full rounded h-96"
                style={{
                  backgroundImage: `url(${article.image.childImageSharp.fluid.base64})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>

              <h2 className="block mt-8 text-base font-semibold tracking-wide text-center text-green-500 uppercase">
                {category.name}
              </h2>
              <h1 className="block mt-2 font-serif text-4xl font-extrabold tracking-tight text-center text-gray-900 sm:text-6xl">
                {article.title}
              </h1>
              <p className="mt-8 text-xl italic leading-8 text-gray-500">
                {article.description}
              </p>
            </div>
            <div className="mx-auto mt-6">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </div>

          <Products products={products} />

          <nav className="mt-24 blog-post-nav">
            <ul className="sm:flex">
              <li className="flex items-center w-full px-6 border h-28">
                <div>
                  <div className="text-gray-400">Article précédent</div>
                  {previous ? (
                    <Link
                      to={`/articles/${previous.frontmatter.category}/${previous.frontmatter.url}/`}
                      className="font-serif text-xl hover:text-green-500"
                      rel="prev"
                    >
                      ← {previous.frontmatter.title}
                    </Link>
                  ) : (
                    <div className="font-serif text-xl">Fin</div>
                  )}
                </div>
              </li>
              <li className="flex items-center w-full px-6 border sm:justify-end h-28">
                <div>
                  <div className="text-gray-400">Article suivant</div>
                  {next ? (
                    <Link
                      to={`/articles/${next.frontmatter.category}/${next.frontmatter.url}/`}
                      className="font-serif text-xl hover:text-green-500"
                      rel="next"
                    >
                      {next.frontmatter.title} →
                    </Link>
                  ) : (
                    <div className="font-serif text-xl sm:text-right">Fin</div>
                  )}
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </Mdx>
    </Layout>
  )
}

export default Article

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $category: String
  ) {
    article: mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        category
        description
        url
        date
        updated_at
        image {
          childImageSharp {
            fluid(jpegQuality: 100, fit: COVER, base64Width: 1000) {
              base64
              src
            }
          }
        }
      }
    }
    category: categoriesJson(url: { eq: $category }) {
      id
      name
      url
    }
    previous: mdx(id: { eq: $previousPostId }) {
      frontmatter {
        title
        url
        category
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      frontmatter {
        title
        url
        category
      }
    }
  }
`

import { Link } from "gatsby"
import React from "react"

export const TableOfContent: React.FC<{
  anchors: { id: string; title: string }[]
}> = ({ anchors }) => (
  <div className="mx-auto mt-4">
    <div className="w-full h-1 rounded-full bg-gradient-to-r from-indigo-500 to-pink-500"></div>
    <div className="max-w-screen-md mx-auto mt-8">
      <h3 className="text-4xl font-bold">The research</h3>
      <ul className="mt-4 space-y-2 text-xl">
        {anchors.map(({ id, title }) => (
          <li>
            <Link className="underline" to={`#${id}`}>
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

import React from "react"

export const H1: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="mx-auto mt-12 font-serif prose prose-xl">
    <h1 id={id}>{children}</h1>
  </div>
)

export const H2: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="mx-auto mt-12 font-serif prose prose-xl">
    <h2 id={id}>{children}</h2>
  </div>
)

export const H3: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="mx-auto mt-12 font-serif prose prose-xl">
    <h3 id={id}>{children}</h3>
  </div>
)

export const H4: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="mx-auto mt-12 font-serif prose prose-xl">
    <h4 id={id}>{children}</h4>
  </div>
)

export const H5: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="mx-auto mt-12 font-serif prose prose-xl">
    <h5 id={id}>{children}</h5>
  </div>
)

export const H6: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="mx-auto mt-12 font-serif prose prose-xl">
    <h6 id={id}>{children}</h6>
  </div>
)

import { OutboundLink } from "gatsby-plugin-google-analytics"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"

import configuration from "../configuration/products.json"

const CheckIcon = () => (
  <svg
    className="flex-shrink-0 w-6 h-6 text-green-500"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 13l4 4L19 7"
    />
  </svg>
)

const HiddenLink: React.FC<{ to: string; className }> = props => {
  const [to, setTo] = useState<string | null>(null)

  useEffect(() => {
    setTo(props.to)
  }, [])

  return (
    <OutboundLink
      href={to || "#"}
      target="_blank"
      {...(to ? { rel: "sponsored" } : {})}
      className={props.className}
    >
      {props.children}
    </OutboundLink>
  )
}

export const Products: React.FC<{
  products: Array<{
    name: string
    url: string
    stars: number
    illustration: string
    amazon: string | null
    description: string
    price: string
    advantages: string[]
  }>
}> = ({ products }) => {
  const [first, favorite, last] = products

  return (
    <div className="">
      <div className="pb-8 mt-16 lg:mt-20 lg:pb-8">
        <div className="relative z-0">
          <div className="absolute inset-0 h-5/6 lg:h-2/3"></div>
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative lg:grid lg:grid-cols-7">
              {first && (
                <div className="max-w-md mx-auto lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                  <div className="flex flex-col h-full overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-l-lg">
                    <div className="flex flex-col flex-1">
                      <div className="px-6 py-10 bg-white">
                        <div>
                          <HiddenLink
                            to={first.url || first.amazon}
                            className="block text-xl font-medium text-center text-gray-900"
                          >
                            {first.name}
                          </HiddenLink>
                          <div className="flex items-center justify-center mt-4">
                            <div
                              style={{
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundImage: `url(${first.illustration})`,
                              }}
                              className="w-28 h-28"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-between flex-1 p-6 border-t-2 border-gray-100 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul className="space-y-4">
                          {first.advantages.map(value => (
                            <li className="flex items-start" key={value}>
                              <div className="flex-shrink-0">
                                <CheckIcon />
                              </div>
                              <p className="ml-3 text-base font-medium text-gray-500">
                                {value}
                              </p>
                            </li>
                          ))}
                        </ul>
                        {first.amazon && (
                          <div className="mt-8">
                            <div className="rounded-lg shadow-md">
                              <HiddenLink
                                to={first.amazon}
                                className="block w-full px-6 py-3 text-base font-medium text-center text-green-500 bg-white border border-transparent rounded-lg hover:bg-gray-50"
                              >
                                {configuration.amazon}
                              </HiddenLink>
                            </div>
                          </div>
                        )}
                        {first.url && (
                          <div className="mt-8">
                            <div className="rounded-lg shadow-md">
                              <HiddenLink
                                to={first.url}
                                className="block w-full px-6 py-3 text-base font-medium text-center text-green-500 bg-white border border-transparent rounded-lg hover:bg-gray-50"
                              >
                                {configuration.url}
                              </HiddenLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="max-w-lg mx-auto mt-10 lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                <div className="relative z-10 rounded-lg shadow-xl">
                  <div
                    className="absolute inset-0 border-2 border-green-500 rounded-lg pointer-events-none"
                    aria-hidden="true"
                  ></div>
                  <div className="absolute inset-x-0 top-0 transform translate-y-px">
                    <div className="flex justify-center transform -translate-y-1/2">
                      <span className="inline-flex px-4 py-1 text-sm font-semibold tracking-wider text-white uppercase bg-green-500 rounded-full">
                        {configuration.favorite}
                      </span>
                    </div>
                  </div>
                  <div className="px-6 pt-12 pb-10 bg-white rounded-t-lg">
                    <div>
                      <HiddenLink
                        to={favorite.url || favorite.amazon}
                        className="block text-3xl font-semibold text-center text-gray-900 sm:-mx-6"
                      >
                        {favorite.name}
                      </HiddenLink>
                      <div className="flex items-center justify-center mt-4">
                        <div
                          style={{
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundImage: `url(${favorite.illustration})`,
                          }}
                          className="w-48 h-48"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-6 pt-10 pb-8 border-t-2 border-gray-100 rounded-b-lg bg-gray-50 sm:px-10 sm:py-10">
                    <ul className="space-y-4">
                      {favorite.advantages.map(value => (
                        <li className="flex items-start" key={value}>
                          <div className="flex-shrink-0">
                            <CheckIcon />
                          </div>
                          <p className="ml-3 text-base font-medium text-gray-500">
                            {value}
                          </p>
                        </li>
                      ))}
                    </ul>
                    {favorite.amazon && (
                      <div className="mt-10">
                        <div className="rounded-lg shadow-md">
                          <HiddenLink
                            to={favorite.amazon}
                            className="block w-full px-6 py-4 text-xl font-medium leading-6 text-center text-gray-900 bg-white border border-transparent rounded-lg hover:bg-gray-100"
                          >
                            {configuration.amazon}
                          </HiddenLink>
                        </div>
                      </div>
                    )}
                    {favorite.url && (
                      <div className="mt-10">
                        <div className="rounded-lg shadow-md">
                          <HiddenLink
                            to={favorite.url}
                            className="block w-full px-6 py-4 text-xl font-medium leading-6 text-center text-white bg-green-500 border border-transparent rounded-lg hover:bg-green-600"
                          >
                            {configuration.url}
                          </HiddenLink>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {last && (
                <div className="max-w-md mx-auto mt-10 lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                  <div className="flex flex-col h-full overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-r-lg">
                    <div className="flex flex-col flex-1">
                      <div className="px-6 py-10 bg-white">
                        <div>
                          <HiddenLink
                            to={last.url || last.amazon}
                            className="block text-xl font-medium text-center text-gray-900"
                          >
                            {last.name}
                          </HiddenLink>
                          <div className="flex items-center justify-center mt-4">
                            <div
                              style={{
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundImage: `url(${last.illustration})`,
                              }}
                              className="w-28 h-28"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-between flex-1 p-6 border-t-2 border-gray-100 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul className="space-y-4">
                          {last.advantages.map(value => (
                            <li className="flex items-start" key={value}>
                              <div className="flex-shrink-0">
                                <CheckIcon />
                              </div>
                              <p className="ml-3 text-base font-medium text-gray-500">
                                {value}
                              </p>
                            </li>
                          ))}
                        </ul>
                        {last.amazon && (
                          <div className="mt-8">
                            <div className="rounded-lg shadow-md">
                              <HiddenLink
                                to={last.amazon}
                                className="block w-full px-6 py-3 text-base font-medium text-center text-green-500 bg-white border border-transparent rounded-lg hover:bg-gray-50"
                              >
                                {configuration.amazon}
                              </HiddenLink>
                            </div>
                          </div>
                        )}
                        {last.url && (
                          <div className="mt-8">
                            <div className="rounded-lg shadow-md">
                              <HiddenLink
                                to={last.url}
                                className="block w-full px-6 py-3 text-base font-medium text-center text-green-500 bg-white border border-transparent rounded-lg hover:bg-gray-50"
                              >
                                {configuration.url}
                              </HiddenLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { MDXProvider } from "@mdx-js/react"
import * as Shortcodes from "./article"

const components = {
  p: props => <Shortcodes.Text {...props} />,
  a: props => <Shortcodes.A {...props} />,
  ul: props => <Shortcodes.Ul {...props} />,
  ol: props => <Shortcodes.Ol {...props} />,
  li: props => <Shortcodes.Li {...props} />,
  h1: props => <Shortcodes.H1 {...props} />,
  h2: props => <Shortcodes.H2 {...props} />,
  h3: props => <Shortcodes.H3 {...props} />,
  h4: props => <Shortcodes.H4 {...props} />,
  h5: props => <Shortcodes.H5 {...props} />,
  h6: props => <Shortcodes.H6 {...props} />,
  hr: props => <Shortcodes.Separator {...props} />,
}

export const Mdx = ({ children }) => {
  return (
    <MDXProvider components={{ ...Shortcodes, ...components }}>
      {children}
    </MDXProvider>
  )
}

export default Mdx
